import { TextField, CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { literal, object, string } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import arrow from "../assets/photos/arrow.svg";
import styled from "styled-components";
import emailjs from "emailjs-com";

const CssTextField = styled(TextField)({
  "& label.Mui-focused:not(.Mui-error)": {
    color: "#002A3A",
  },
  "& .MuiInput-underline:after:not(.Mui-error)": {
    borderBottomColor: "#002A3A",
  },
  "& .MuiOutlinedInput-root:not(.Mui-error)": {
    "& fieldset": {
      border: "1px solid #002A3A",
    },
    "&:hover fieldset": {
      border: "1px solid #002A3A",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #002A3A",
    },
  },
});

const contactSchema = object({
  name: string()
    .min(1, { message: "Required" })
    .max(32, { message: "Name must be less than 100 characters" }),
  email: string()
    .min(1, { message: "Required" })
    .email({ message: "Email is invalid" }),
  phone_number: string()
    .regex(/^\+(?:[0-9]●?){6,14}[0-9]$/, {
      message: "Invalid phone number",
    })
    .optional()
    .or(literal("")),
  website: string()
    .url({ message: "Website url is invalid" })
    .optional()
    .or(literal("")),
  message: string()
    .min(1, { message: "Required" })
    .min(20, { message: "Message must be more than 20 characters" })
    .max(500, { message: "Message must be less than 500 characters" }),
});

export const ContactUs = ({ setformSubmited, setSuccess, goBack }) => {
  const {
    register,
    formState: { errors, isSubmitSuccessful },
    reset,
    handleSubmit,
  } = useForm({
    resolver: zodResolver(contactSchema),
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmitHandler = (values, event) => {
    event.preventDefault();

    const templateParams = {
      from_name: values.name,
      from_email: values.email,
      from_phone_number: values.phone_number || "Not provided",
      from_website: values.website || "Not provided",
      message: values.message,
    };
    setLoading(true);
    emailjs
      .send(
        "service_rxjdzjg",
        "template_d0dyhfv",
        templateParams,
        "xxgMtQn47_Jf5t-b-"
      )
      .then(
        () => {
          setformSubmited(true);
          setLoading(false);
          setSuccess(true);
        },
        () => {
          setformSubmited(true);
          setLoading(false);
          setSuccess(false);
        }
      );
  };

  return (
    <>
      <div className="form-container contact">
        <button className="go-back-button" onClick={() => goBack()}>
          <img src={arrow} alt="Arrow" />
          GO BACK
        </button>
        <h1>Contact Us</h1>
        <p className="small-text">
          Please, tell us about yourself and describe your inquiry
        </p>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <CssTextField
            id="outlined-basic"
            label="Full Name"
            variant="outlined"
            margin="normal"
            error={!!errors["name"]}
            helperText={errors["name"] ? errors["name"].message : " "}
            {...register("name")}
          />
          <CssTextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            margin="normal"
            error={!!errors["email"]}
            helperText={errors["email"] ? errors["email"].message : " "}
            {...register("email")}
          />
          <CssTextField
            id="outlined-basic"
            label="Phone Number"
            variant="outlined"
            margin="normal"
            error={!!errors["phone_number"]}
            helperText={
              errors["phone_number"] ? errors["phone_number"].message : " "
            }
            {...register("phone_number")}
          />
          <CssTextField
            id="outlined-basic"
            label="Business Website"
            variant="outlined"
            margin="normal"
            error={!!errors["website"]}
            helperText={errors["website"] ? errors["website"].message : " "}
            {...register("website")}
          />
          <CssTextField
            id="outlined-basic"
            label="Message"
            variant="outlined"
            margin="normal"
            multiline
            minRows={4}
            error={!!errors["message"]}
            helperText={errors["message"] ? errors["message"].message : " "}
            {...register("message")}
          />
          <button className="blue-button" disabled={loading}>
            {loading ? (
              <CircularProgress size={24} style={{ color: "white" }} />
            ) : (
              "Submit"
            )}
          </button>
        </form>
      </div>
    </>
  );
};
