export const Message = ({ title, message, backToHome }) => {
  return (
    <>
      <div className="messageContainer">
        <h2>{title}</h2>
        <p className="small-text">{message}</p>
        <button onClick={() => backToHome()}>Back to Home</button>
      </div>
    </>
  );
};
