import { LoginForm } from "../login_form/LoginForm";
import videoBg from "../assets/videos/globe.mp4";
import smartGlobalLogo from "../assets/photos/smartGlobal.svg";
import { useState } from "react";
import { ContactUs } from "../contact_us/ContactUs";
import { Message } from "../message/Message";

export const Login = () => {
  const [showContactForm, setShowContactForm] = useState(false);
  const [formSubmited, setformSubmited] = useState(false);
  const [success, setSuccess] = useState(false);

  function handleButtonClick() {
    setShowContactForm(!showContactForm);
  }

  function backToHome() {
    setShowContactForm(false);
    setformSubmited(false);
  }

  return (
    <>
      <div className="main-container">
        <div className="main-container-header">
          <img src={smartGlobalLogo} alt="Smart Global Logo" />
          {!showContactForm && (
            <button onClick={handleButtonClick}>Contact Us</button>
          )}
        </div>
        {showContactForm ? (
          !formSubmited ? (
            <ContactUs
              setformSubmited={setformSubmited}
              setSuccess={setSuccess}
              goBack={handleButtonClick}
            />
          ) : (
            <Message
              title={success ? "Success!" : "Failed."}
              message={
                success
                  ? "Your message has been received. We will reply to you shorty."
                  : "Your message has not been sent. Please try again later."
              }
              backToHome={backToHome}
            />
          )
        ) : (
          <LoginForm />
        )}
      </div>
      <div className="video-container">
        <video loading="lazy" autoPlay="autoplay" loop muted>
          <source src={videoBg} type="video/mp4"></source>
        </video>

        <div className="text-container">
          <p>© 2022, Smart Global Trading DOO, All Rights Reserved</p>
        </div>
      </div>
    </>
  );
};
