import { TextField, CircularProgress } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { object, string } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState, useEffect } from "react";

const CssTextField = styled(TextField)({
  "& label.Mui-focused:not(.Mui-error)": {
    color: "#002A3A",
  },
  "& .MuiInput-underline:after:not(.Mui-error)": {
    borderBottomColor: "#002A3A",
  },
  "& .MuiOutlinedInput-root:not(.Mui-error)": {
    "& fieldset": {
      border: "1px solid #002A3A",
    },
    "&:hover fieldset": {
      border: "1px solid #002A3A",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #002A3A",
    },
  },
});

const loginSchema = object({
  userId: string().min(1, { message: "Required" }),
  customerNumber: string().min(1, { message: "Required" }),
  password: string().min(1, { message: "Required" }),
});

export const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [authenticate, setAuthenticate] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const {
    register,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
  } = useForm({
    resolver: zodResolver(loginSchema),
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmitHandler = (values, event) => {
    event.preventDefault();
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      setAuthenticate(true);
    }, 500);
  };

  return (
    <>
      <div className="form-container">
        <h1>
          Welcome to the Smart Global trading website for European wholesalers.
        </h1>
        <p className="small-text">
          If you are not registered please contact your sales manager.
        </p>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <CssTextField
            id="userId"
            label="User ID"
            variant="outlined"
            margin="normal"
            onFocus={() => setAuthenticate(false)}
            error={!!errors["userId"] || authenticate}
            helperText={errors["userId"] ? errors["userId"].message : " "}
            {...register("userId")}
          />
          <CssTextField
            id="customerNumber"
            label="Customer Number"
            variant="outlined"
            margin="normal"
            onFocus={() => setAuthenticate(false)}
            error={!!errors["customerNumber"] || authenticate}
            helperText={
              errors["customerNumber"] ? errors["customerNumber"].message : " "
            }
            {...register("customerNumber")}
          />
          <CssTextField
            id="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            onFocus={() => setAuthenticate(false)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    style={{ opacity: 0.5 }}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            margin="normal"
            error={!!errors["password"] || authenticate}
            helperText={
              errors["password"]
                ? errors["password"].message
                : "" || authenticate
                ? "User ID, customer number or password is incorrect."
                : " "
            }
            {...register("password")}
          />
          <p className="small-text">
            If you have problems using our wholesale portal or if you have
            forgotten your credentials contact{" "}
            <a href="mailto: info@globaltrading.rs">info@globaltrading.rs</a>{" "}
            for help.
          </p>
          <button className="blue-button" disabled={loading}>
            {loading ? (
              <CircularProgress size={24} style={{ color: "white" }} />
            ) : (
              "Log In"
            )}
          </button>
        </form>
      </div>
    </>
  );
};
